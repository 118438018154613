































































































import { defineComponent } from '@vue/composition-api'
import SelectStatusLogisticSession from '@/components/molecules/select/selectStatusLogisticSession.vue'
import { ValidationObserver } from 'vee-validate'
import SelectTrainingOfficer from '@/components/molecules/select/trainingOfficer.vue'
import { mapActions, mapState } from 'vuex'
import ConfirmModal from '@/components/molecules/dialog/confirmModal.vue'
import { User } from '@/api/interfaces/user'
import { UserApplication } from '@/api/interfaces/userApplication'

export default defineComponent({
  components: { ConfirmModal, SelectTrainingOfficer, SelectStatusLogisticSession },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      trainingOfficer: null as null | User,
      targetTrainingOfficer: null as null | User,
      showConfirmModal: false,
    }
  },
  methods: {
    ...mapActions('trainingOfficer', {
      transferLogisticSessionsToAnotherTrainingOfficer: 'transferLogisticSessionsToAnotherTrainingOfficer',
    }),
    clear (): void {
      this.trainingOfficer = null
      this.targetTrainingOfficer = null
      this.showConfirmModal = false
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      observer.reset()
    },
    cancel (): void {
      this.clear()
      this.$emit('close')
    },
    async onSubmit (): Promise<void> {
      this.showConfirmModal = true
    },
    async transferLogisticSessions (): Promise<void> {
      const trainingOfficer = this.trainingOfficer as User
      const targetTrainingOfficer = this.targetTrainingOfficer as User

      await this.transferLogisticSessionsToAnotherTrainingOfficer({
        trainingOfficerId: Number((trainingOfficer.userApplication as UserApplication)['@id']?.split('/').pop()),
        targetTrainingOfficerId: Number((targetTrainingOfficer.userApplication as UserApplication)['@id']?.split('/').pop()),
      })
      this.$emit('submit')
      this.clear()
      this.$emit('close')
    },
  },
  computed: {
    ...mapState('trainingOfficer', {
      listTrainingOfficersDriverProgram: 'listTrainingOfficersDriverProgram',
      listTrainingOfficersOthers: 'listTrainingOfficersOthers',
      loadingTrainingOfficersDriverProgram: 'loadingTrainingOfficersDriverProgram',
      loadingTrainingOfficersOthers: 'loadingTrainingOfficersOthers',
      submittingTransferSessionsToAnotherTrainingOfficer: 'submittingTransferSessionsToAnotherTrainingOfficer',
    }),
    trainingOfficersDriverProgramAndOthers (): Array<{ id: number, name: string }> {
      return [
        ...this.listTrainingOfficersDriverProgram,
        ...this.listTrainingOfficersOthers,
      ]
    },
    trainingOfficers (): Array<{ id: number, name: string }> {
      return this.trainingOfficersDriverProgramAndOthers.filter((trainingOfficer: any) => trainingOfficer !== this.targetTrainingOfficer)
    },
    targetTrainingOfficers (): Array<{ id: number, name: string }> {
      return this.trainingOfficersDriverProgramAndOthers.filter((trainingOfficer: any) => trainingOfficer !== this.trainingOfficer)
    },
    getMessageTransferLogisticSessions (): string {
      const trainingOfficer = this.trainingOfficer as null | User
      const targetTrainingOfficer = this.targetTrainingOfficer as null | User

      return this.$t('training_officers.dialog.transfer_logistic_session.confirm_content', {
        training_officer: trainingOfficer?.firstName + ' ' + trainingOfficer?.lastName,
        target_training_officer: targetTrainingOfficer?.firstName + ' ' + targetTrainingOfficer?.lastName,
      }) as string
    },
  },
})
