import { render, staticRenderFns } from "./selectStatusLogisticSession.vue?vue&type=template&id=4dd3d624&scoped=true&"
import script from "./selectStatusLogisticSession.vue?vue&type=script&lang=ts&"
export * from "./selectStatusLogisticSession.vue?vue&type=script&lang=ts&"
import style0 from "./selectStatusLogisticSession.vue?vue&type=style&index=0&id=4dd3d624&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dd3d624",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
