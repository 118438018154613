



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { defineComponent } from '@vue/composition-api'
import SelectStatusLogisticSession from '@/components/molecules/select/selectStatusLogisticSession.vue'
import SelectStatusLogisticSessionCircuit from '@/components/molecules/select/selectStatusLogisticSessionCircuit.vue'
import SelectStatusLogisticSessionSupplierRelationship from '@/components/molecules/select/selectStatusLogisticSessionSupplierRelationship.vue'
import { userConnectedCanAccess } from '@/composables/UserGrant'
import { TrainingOfficerView } from '@/api/enums/trainingOfficerView'
import { mapActions, mapGetters } from 'vuex'
import ListTextFilter from '@/components/base/ListTextFilter.vue'
import ListSelectFilter from '@/components/base/ListSelectFilter.vue'
import DateField from '@/components/base/DateField.vue'
import DateFieldFilter from '@/components/base/DateFieldFilter.vue'
import { useDate } from '@/composables/date'
import { TrainingSessionTrainingOfficerView } from '@/api/interfaces/trainingSessionTrainingOfficerView'
import { Company } from '@/api/interfaces/company'
import { NeedTraining } from '@/api/interfaces/needtraining'
import { LogisticSessionTrainingOfficerView } from '@/api/interfaces/logisticSessionTrainingOfficerView'
import { TrainingOfficerCompany } from '@/api/interfaces/logisticSession'
import { UserApplication } from '@/api/interfaces/userApplication'
import { NeedTrainingStatus } from '@/api/enums/needTrainingStatus'
import { VueEditor } from 'vue2-editor'
import Vue from 'vue'

export default defineComponent({
  name: 'dataTableTrainingOfficer',
  components: {
    DateFieldFilter,
    DateField,
    ListTextFilter,
    ListSelectFilter,
    SelectStatusLogisticSession,
    SelectStatusLogisticSessionCircuit,
    SelectStatusLogisticSessionSupplierRelationship,
    VueEditor,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    filtersHeaders: {
      type: Object,
      required: true,
    },
    showInitials: {
      type: Boolean,
      required: false,
      default: false,
    },
    monthSelected: {
      type: String,
      required: false,
    },
  },
  methods: {
    useDate,
    ...mapActions('trainingOfficer', {
      updateLogisticSession: 'updateLogisticSession',
      updateCommentaryTrainingOfficer: 'updateCommentaryTrainingOfficer',
      updateTrackingDate: 'updateTrackingDate',
      updateTrainingOfficerLogisticSessionDetail: 'updateTrainingOfficerLogisticSessionDetail',
    }),
    getNbPax (trainingSessions: Array<TrainingSessionTrainingOfficerView>, company: Company) {
      let nbPax = 0

      trainingSessions.forEach((trainingSession: TrainingSessionTrainingOfficerView) => {
        trainingSession.needTrainings.forEach((needTraining: NeedTraining) => {
          if (needTraining.company.id === company.id) {
            nbPax++
          }
        })
      })

      return nbPax
    },
    async updateLogisticSessionProperty (id: string, property: string, value: any) {
      // eslint-disable-next-line
      await this.updateLogisticSession({ id, body: { [property]: value } })
    },
    async updateTrackingDateProperty (id: string, property: string, value: any) {
      // eslint-disable-next-line
      await this.updateTrackingDate({ id, body: { [property]: value } })
    },
    async updateTrainingOfficerLogisticSessionDetailProperty (id: string, property: string, value: any) {
      // eslint-disable-next-line
      await this.updateTrainingOfficerLogisticSessionDetail({ id, body: { [property]: value } })
    },
    async updatePropertyCommentaryTrainingOfficer (id: string, commentary: string) {
      // eslint-disable-next-line
      await this.updateCommentaryTrainingOfficer({ id, body: { commentary } })
    },
    getInitialsAddedBy (item: LogisticSessionTrainingOfficerView, company: Company): string {
      const trainingOfficerCompany = item.trainingOfficerCompanies.find((trainingOfficerCompany: TrainingOfficerCompany) => (trainingOfficerCompany.company as Company).id === company.id)

      return trainingOfficerCompany ? (trainingOfficerCompany.addedBy as UserApplication).user.initials : ''
    },
    isCompanyWithoutConvocation (item: LogisticSessionTrainingOfficerView, company: Company): boolean {
      const trainingOfficerCompany = item.trainingOfficerCompanies.find((trainingOfficerCompany: TrainingOfficerCompany) => (trainingOfficerCompany.company as Company).id === company.id)

      return trainingOfficerCompany ? (trainingOfficerCompany.isWithoutConvocation as boolean) : false
    },
    isCompanyWithoutConvention (item: LogisticSessionTrainingOfficerView, company: Company): boolean {
      const trainingOfficerCompany = item.trainingOfficerCompanies.find((trainingOfficerCompany: TrainingOfficerCompany) => (trainingOfficerCompany.company as Company).id === company.id)

      return trainingOfficerCompany ? (trainingOfficerCompany.isWithoutConvention as boolean) : false
    },
    getVehicleClient (needTraining: NeedTraining): string {
      return [
        needTraining.carBrand?.brand,
        needTraining.carModel?.model,
        needTraining.carType?.type,
        needTraining.carEngine?.engine,
      ].filter(Boolean).join(' - ')
    },
    saveScrollYPosition () {
      const tableScrollYPosition = (this.$refs.dataTableElement as Vue).$el.children[0].scrollTop
      if (tableScrollYPosition) {
        localStorage.setItem('actua-ogf-tableScrollY', tableScrollYPosition.toString())
      }
    },
    scrollYToPosition (position: string) {
      (this.$refs.dataTableElement as Vue).$el.children[0].scrollTop = parseInt(position)
    },
  },
  watch: {
    items: function () {
      Vue.nextTick(() => {
        const storageTableScrollYPosition = localStorage.getItem('actua-ogf-tableScrollY')
        if (storageTableScrollYPosition) {
          this.scrollYToPosition(storageTableScrollYPosition)
        }
        localStorage.removeItem('actua-ogf-tableScrollY')
      })
    },
  },
  computed: {
    NeedTrainingStatus () {
      return NeedTrainingStatus
    },
    TrainingOfficerView () {
      return TrainingOfficerView
    },
    ...mapGetters('trainingType', {
      trainingTypesList: 'getListActiveItems',
    }),
    canEditLogisticSession (): boolean {
      return userConnectedCanAccess('LogisticSession Edit')
    },
    columnsOffsetWidth (): number {
      return this.showInitials ? 75 : 0
    },
    getHeaders (): Array<any> {
      const headers = []

      if (this.showInitials) {
        headers.push({
          text: this.$t('training_officers.list.initials'),
          value: 'initials',
          align: 'center',
          width: '75px',
          class: 'table-initials cell-training-officer',
          cellClass: 'table-initials cell-training-officer',
        })
      }

      headers.push(
        {
          text: this.$t('training_officers.list.actions'),
          value: 'actions',
          align: 'center',
          width: '80px',
          class: 'table-actions cell-training-officer',
          cellClass: 'table-actions cell-training-officer',
        },
        {
          text: this.$t('training_officers.list.initials_main_training_officer'),
          value: 'initialsMainTrainingOfficer',
          align: 'center',
          width: '80px',
          class: 'table-initials-main-training-officer cell-training-officer',
          cellClass: 'table-initials-main-training-officer cell-training-officer',
        },
      )

      if (this.view === TrainingOfficerView.DRIVER_PROGRAM) {
        headers.push(
          {
            text: this.$t('training_officers.list.status'),
            value: 'status',
            width: '200px',
            align: 'center',
            class: 'table-status cell-training-officer',
            cellClass: 'table-status cell-training-officer cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.date'),
            value: 'startAt',
            width: '150px',
            class: 'table-date cell-training-officer',
            cellClass: 'table-date cell-training-officer cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.city'),
            value: 'city',
            width: '140px',
            class: 'table-location cell-training-officer',
            cellClass: 'table-location cell-training-officer cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.commentaries'),
            value: 'commentary',
            width: '200px',
            class: 'table-commentary cell-training-officer',
            cellClass: 'table-commentary cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.training_location'),
            value: 'trainingLocation',
            width: '350px',
            class: 'table-training-location cell-training-officer',
            cellClass: 'table-training-location cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.caterer'),
            value: 'caterer',
            width: '200px',
            class: 'table-caterer cell-training-officer',
            cellClass: 'table-caterer cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.trainers'),
            value: 'trainers',
            width: '200px',
            class: 'table-trainers cell-training-officer',
            cellClass: 'table-trainers cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.info_housing'),
            value: 'infoAddressHousing',
            width: '300px',
            class: 'table-info-housing cell-training-officer',
            cellClass: 'table-info-housing cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.commentary_housing'),
            value: 'commentaryHousing',
            width: '200px',
            class: 'table-commentary-housing cell-training-officer',
            cellClass: 'table-commentary-housing cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.schedules'),
            value: 'schedules',
            width: '120px',
            class: 'table-schedules cell-training-officer',
            cellClass: 'table-schedules cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.need_trainings_names'),
            value: 'needTrainingsNames',
            width: '200px',
            class: 'table-need-trainings-names cell-training-officer',
            cellClass: 'table-need-trainings-names cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.need_trainings_status'),
            value: 'needTrainingsStatus',
            width: '200px',
            class: 'table-need-trainings-status cell-training-officer',
            cellClass: 'table-need-trainings-status cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.client'),
            value: 'needTrainingsCompanies',
            width: '200px',
            class: 'table-client cell-training-officer',
            cellClass: 'table-client cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.need_trainings_phones'),
            value: 'needTrainingsPhones',
            width: '250px',
            class: 'table-need-trainings-phones cell-training-officer',
            cellClass: 'table-need-trainings-phones cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.need_trainings_emails'),
            value: 'needTrainingsEmails',
            width: '250px',
            class: 'table-need-trainings-emails cell-training-officer',
            cellClass: 'table-need-trainings-emails cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.vehicle_client'),
            value: 'vehicleClient',
            width: '250px',
            class: 'table-vehicle-client cell-training-officer',
            cellClass: 'table-vehicle-client cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.convocation'),
            value: 'convocation',
            width: '200px',
            class: 'table-convocation cell-training-officer',
            cellClass: 'table-convocation cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.folder_vehicle'),
            value: 'folderVehicle',
            width: '200px',
            class: 'table-folder-vehicle cell-training-officer',
            cellClass: 'table-folder-vehicle cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.sms_and_mail'),
            value: 'smsAndMail',
            width: '200px',
            class: 'table-sms-and-mail cell-training-officer',
            cellClass: 'table-sms-and-mail cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.mail_concession'),
            value: 'mailConcession',
            width: '200px',
            class: 'table-mail-concession cell-training-officer',
            cellClass: 'table-mail-concession cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.sms_client'),
            value: 'smsClient',
            width: '200px',
            class: 'table-sms-client cell-training-officer',
            cellClass: 'table-sms-client cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.envoi_docs'),
            value: 'folderPostTrainingSession',
            width: '200px',
            class: 'table-envoi-docs cell-training-officer',
            cellClass: 'table-envoi-docs cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.client'),
            value: 'clientWithInitialsTrainingOfficer',
            width: '200px',
            class: 'table-client-with-initials-training-officer cell-training-officer',
            cellClass: 'table-client-with-initials-training-officer cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.report'),
            value: 'report',
            width: '200px',
            class: 'table-report cell-training-officer',
            cellClass: 'table-report cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.billing'),
            value: 'billing',
            width: '200px',
            class: 'table-billing cell-training-officer',
            cellClass: 'table-billing cell-training-officer',
          },
        )
      } else if (this.view === TrainingOfficerView.OTHERS) {
        headers.push(
          {
            text: this.$t('training_officers.list.status'),
            value: 'status',
            width: '200px',
            align: 'center',
            class: 'table-status cell-training-officer',
            cellClass: 'table-status cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.date'),
            value: 'startAt',
            width: '150px',
            class: 'table-date cell-training-officer',
            cellClass: 'table-date cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.city'),
            value: 'city',
            width: '140px',
            class: 'table-location cell-training-officer',
            cellClass: 'table-location cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.commentaries'),
            value: 'commentaryViewOthers',
            width: '200px',
            class: 'table-commentary cell-training-officer',
            cellClass: 'table-commentary cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.client'),
            value: 'client',
            width: '200px',
            class: 'table-client cell-training-officer',
            cellClass: 'table-client cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.training_type'),
            value: 'trainingType',
            width: '200px',
            class: 'table-training-type cell-training-officer',
            cellClass: 'table-training-type cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.training_location'),
            value: 'trainingLocation',
            width: '350px',
            class: 'table-training-location cell-training-officer',
            cellClass: 'table-training-location cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.circuit'),
            value: 'commentaryTrainingLocation',
            width: '200px',
            class: 'table-circuit cell-training-officer',
            cellClass: 'table-circuit cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.trainers'),
            value: 'trainers',
            width: '200px',
            class: 'table-trainers cell-training-officer',
            cellClass: 'table-trainers cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.info_housing'),
            value: 'infoAddressHousing',
            width: '300px',
            class: 'table-info-housing cell-training-officer',
            cellClass: 'table-info-housing cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.commentary_housing'),
            value: 'commentaryHousing',
            width: '200px',
            class: 'table-commentary-housing cell-training-officer',
            cellClass: 'table-commentary-housing cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.caterer'),
            value: 'caterer',
            width: '200px',
            class: 'table-caterer cell-training-officer',
            cellClass: 'table-caterer cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.nb_pax'),
            value: 'nbPax',
            width: '120px',
            class: 'table-nb-pax cell-training-officer',
            cellClass: 'table-nb-pax cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.client'),
            value: 'client2',
            width: '200px',
            class: 'table-client cell-training-officer',
            cellClass: 'table-client cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.convention'),
            value: 'convention',
            width: '200px',
            class: 'table-convention cell-training-officer',
            cellClass: 'table-convention cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.convocation_others'),
            value: 'convocation',
            width: '200px',
            class: 'table-convocation cell-training-officer',
            cellClass: 'table-convocation cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.folder_vehicle_others'),
            value: 'folderVehicle',
            width: '200px',
            class: 'table-folder-vehicle cell-training-officer',
            cellClass: 'table-folder-vehicle cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.folder_post_training_session'),
            value: 'folderPostTrainingSession',
            width: '200px',
            class: 'table-folder-post-training-session cell-training-officer',
            cellClass: 'table-folder-post-training-session cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.report_audit'),
            value: 'report',
            width: '200px',
            class: 'table-report cell-training-officer',
            cellClass: 'table-report cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.e-learning'),
            value: 'eLearning',
            width: '200px',
            class: 'table-e-learning cell-training-officer',
            cellClass: 'table-e-learning cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.billing'),
            value: 'billing',
            width: '200px',
            class: 'table-billing cell-training-officer',
            cellClass: 'table-billing cell-training-officer',
          },
        )
      } else if (this.view === TrainingOfficerView.SUPPLIER_RELATIONSHIP) {
        headers.push(
          {
            text: this.$t('training_officers.list.status_circuit'),
            value: 'statusCircuit',
            width: '200px',
            align: 'center',
            class: 'table-status-circuit cell-training-officer',
            cellClass: 'table-status-circuit cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.status_supplier_relationship'),
            value: 'statusSupplierRelationship',
            width: '200px',
            align: 'center',
            class: 'table-status-supplier-relationship cell-training-officer',
            cellClass: 'table-status-supplier-relationship cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.date'),
            value: 'startAt',
            width: '150px',
            class: 'table-date-supplier-relationship cell-training-officer',
            cellClass: 'table-date-supplier-relationship cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.city'),
            value: 'city',
            width: '140px',
            class: 'table-location-supplier-relationship cell-training-officer',
            cellClass: 'table-location-supplier-relationship cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.training_type'),
            value: 'trainingType',
            width: '200px',
            class: 'table-training-type cell-training-officer',
            cellClass: 'table-training-type cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.trainers'),
            value: 'trainers',
            width: '200px',
            class: 'table-trainers cell-training-officer',
            cellClass: 'table-trainers cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.training_location_type'),
            value: 'trainingLocation.type',
            width: '200px',
            class: 'table-training-location cell-training-officer',
            cellClass: 'table-training-location cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.training_location'),
            value: 'trainingLocation',
            width: '350px',
            class: 'table-training-location cell-training-officer',
            cellClass: 'table-training-location cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.commentary_training_location'),
            value: 'commentaryTrainingLocation',
            width: '200px',
            class: 'table-commentary-training-location cell-training-officer',
            cellClass: 'table-commentary-training-location cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.info_housing'),
            value: 'infoAddressHousing',
            width: '300px',
            class: 'table-info-housing cell-training-officer',
            cellClass: 'table-info-housing cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.commentary_housing'),
            value: 'commentaryHousing',
            width: '200px',
            class: 'table-commentary-housing cell-training-officer',
            cellClass: 'table-commentary-housing cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.caterer'),
            value: 'caterer',
            width: '300px',
            class: 'table-caterer cell-training-officer',
            cellClass: 'table-caterer cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.commentary_caterer'),
            value: 'commentaryCaterer',
            width: '200px',
            class: 'table-commentary-caterer cell-training-officer',
            cellClass: 'table-commentary-caterer cell-training-officer',
          },
          {
            text: this.$t('training_officers.list.commentary_supplier_relationship'),
            value: 'commentarySupplierRelationshipOfficer',
            width: '200px',
            class: 'table-commentary-supplier-relationship cell-training-officer',
            cellClass: 'table-commentary-supplier-relationship cell-training-officer',
          },
        )
      }

      return headers
    },
  },
})
