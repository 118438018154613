


























import { defineComponent } from '@vue/composition-api'
import { ItemStatusLogisticSession } from '@/api/enums/statusLogisticSession'
import { itemsStatusLogisticSession } from '@/config-constantes'

export default defineComponent({
  name: 'SelectStatusLogisticSession',
  props: {
    value: {
      type: [String],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendIcon: {
      type: String,
      required: false,
      default: 'mdi-chevron-down',
    },
  },
  data () {
    return {
      status: this.value,
    }
  },
  watch: {
    value: {
      handler () {
        this.status = this.value
      },
    },
  },
  methods: {
    onInput (value: string) {
      this.$emit('input', value)
    },
    onChange (value: string) {
      this.$emit('change', value)
    },
  },
  computed: {
    selectedClass (): string {
      const selectedItem = itemsStatusLogisticSession.find((item: ItemStatusLogisticSession) => item.value === this.status)
      return selectedItem ? selectedItem.class : ''
    },
  },
})
