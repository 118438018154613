




























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SelectStatusLogisticSessionSupplierRelationship',
  props: {
    value: {
      type: [Array],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendIcon: {
      type: String,
      required: false,
      default: 'mdi-chevron-down',
    },
  },
  data () {
    return {
      status: this.value,
    }
  },
  watch: {
    value: {
      handler () {
        this.status = this.value
      },
    },
  },
  methods: {
    onInput (value: Array<string>) {
      this.$emit('input', value)
    },
    onChange (value: Array<string>) {
      this.$emit('change', value)
    },
  },
})
